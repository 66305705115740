import React, { FC, useState, useEffect, useContext } from 'react';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Tabs,
  ButtonGroup,
  Tab,
  Typography,
  Divider,
  Hidden,
  Tooltip,
  CircularProgress,
  TextField,
  Badge
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import ContentInvoice from './components/ContentInvoice';
import { Page, Breadcrumb, StandardConfirmationDialog, PaperCustom } from 'components';
import { BLUE_PRIMARY, WHITE, RED, YELLOW } from 'constants/colors';
import {
  GET_INVOICE_DETAIL_BASE_URL,
  INVOICE_ITEM_UPDATE_BASE_URL,
  COMPANY_BASE_URL,
  INVOICE_BASE_URL,
  INVOICE_PAYMENT_BASE_URL,
  INVOICE_PIECE_BASE_URL,
  INVOICE_ROUNDING_BASE_URL,
  RETURN_INVOICE_BASE_URL,
  BANK_BASE_URL,
  INVOICE_REQUEST_EDIT_BASE_URL,
  INVOICE_REQUEST_CANCEL_BASE_URL,
  INVOICE_ITEM_DELETE_URL,
  INVOICE_CANCEL_BASE_URL,
  INVOICE_NEW_EDIT_BASE_URL,
  GET_INVOICE_PAYMENT_RETURN_BASE_URL,
  GET_INVOICE_PAYMENT_LIST_BASE_URL,
  PARTNER_DETAIL_BASE_URL,
  PARTNER_BASE_URL
} from 'constants/url';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { dummyInvoice, dummyCompany, dummyBank, dummyPartner } from 'utils/dummy';
import { TabPanel, a11yProps } from 'components';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import NumberFormat from 'react-number-format';
import useRole from 'hooks/useRole';
import PaymentMethod from 'typings/enum/PaymentMethod';
import PaymentStatus from 'typings/enum/PaymentStatus';
import PaymentTable from './components/PaymentTable';
import PieceTable from './components/PieceTable';
import PartnerModal from './components/PartnerModal';
import PrintConfirm from './components/PrintConfirm';
import TypeUser from 'typings/enum/TypeUser';
import RemarkDialog from './components/RemarkDialog';
import useToggle from 'hooks/useToggle';
import PrintIcon from '@material-ui/icons/Print';
import PrintDisabledIcon from '@material-ui/icons/PrintDisabled';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 2,
    marginBottom: 10,
    overflowX: 'auto'
  },
  boxTitle: {
    marginBottom: 8
  },
  panel: {
    width: '100%'
  },
  cancelConfirmButton: {
    backgroundColor: RED
  }
});

const InvoiceDetailPage: FC = () => {
  const { match } = useRouter();
  // eslint-disable-next-line
  const params = match.params.id;
  const classes = useStyles();
  const { currentUser } = useContext(CurrentUserContext);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [partner, setPartner] = useState<PartnerModel>(dummyPartner);
  const [invoice, setInvoice] = useState<InvoiceModel>(dummyInvoice);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [invoiceItem, setInvoiceItem] = useState<InvoiceItemModel[]>([]);
  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentModel[]>([]);
  const [invoicePiece, setInvoicePiece] = useState<InvoicePieceModel[]>([]);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);

  const [id, setId] = useState<number>(0);
  const [value, setValue] = useState(0);

  const [paymentNumber, setPaymentNumber] = useState<string>('');
  const [payDate, setPayDate] = useState<Date>(new Date());
  const [totalPay, setTotalPay] = useState<number | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const [giroBank, setGiroBank] = useState<string>('');
  const [giroNumber, setGiroNumber] = useState<string>('');
  const [giroAmount, setGiroAmount] = useState<number>(0);
  const [giroDueDate, setGiroDate] = useState<Date>(new Date());

  const [giroBankMessage, setGiroBankMessage] = useState<string>('');
  const [giroNumberMessage, setGiroNumberMessage] = useState<string>('');
  const [giroAmountMessage, setGiroAmountMessage] = useState<string>('');
  const [giroDueDateMessage, setGiroDateMessage] = useState<string>('');

  const [paymentMethodMessage, setPaymentMethodMessage] = useState<string>('');
  const [totalPayMessage, setTotalPayMessage] = useState<string>('');
  const [payDateMessage, setPayDateMessage] = useState<string>('');

  const [piecePrice, setPiecePrice] = useState<number>(0);
  const [piecePriceMessage, setPiecePriceMessage] = useState<string>('');
  const [pieceNote, setPieceNote] = useState<string>('');
  const [pieceType, setPieceType] = useState<string>('OTHER');

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [loadingPiece, setLoadingPiece] = useState<boolean>(false);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [loadingTotalPrice, setLoadingTotalPrice] = useState<boolean>(false);
  const [loadingBank, setLoadingBank] = useState<boolean>(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const [indexCollapse, setIndexCollapse] = useState<number>(-1);
  const [indexEdit, setIndexEdit] = useState<number>(-1);

  const [openCreatePiece, setOpenCreatePiece] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isRequestEdit, setRequestEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTotalPrice, setOpenTotalPrice] = useState<boolean>(false);

  const [bank, setBank] = useState<BankModel>(dummyBank);
  const [banks, setBanks] = useState<BankModel[]>([dummyBank]);

  const [itemId, setItemId] = useState<{ id: number; notes: string; isRequestEdit: boolean }[]>([]);
  const [typeEdit, setTypeEdit] = useState<string>('');

  const [isCancel, setCancel] = useState<boolean>(false);
  const [isPartner, setIsPartner] = useState<boolean>(false);
  const [isRequestCancel, setRequestCancel] = useState<boolean>(false);
  const [cancelNote, setCancelNote] = useState<string>('');
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const [currentItem, setCurrentItem] = useState<InvoiceItemModel>();
  const [openPartner, setOpenPartner] = useState<boolean>(false);

  const [openCollapseReturn, setOpenCollapseReturn] = useState<boolean>(false);
  const [indexCollapseReturn, setIndexCollapseReturn] = useState<number>(0);
  const [isLoadingCollapseReturn, setLoadingCollapseReturn] = useState<boolean>(false);
  const [invoiceReturn, setInvoiceReturn] = useState<InvoiceReturnModel[]>([]);

  const [openPrintConfirm, setOpenPrintConfirm] = useState<boolean>(false);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  // const [loadingPrintColly, setLoadingPrintColly] = useState<boolean>(false);
  const [messagePrintDate, setMessagePrintDate] = useState<string>('');
  const [messagePrintUser, setMessagePrintUser] = useState<string>('');

  const [remark, setRemark] = useState<string>('');
  const [openRemark, setOpenRemark] = useToggle();
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const isSuperAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.SUPERADMIN]
  });

  const isAdmin = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN]
  });

  const isInvoice = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN03]
  });

  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });

  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });

  const isFinance = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ADMIN04]
  });

  const handleOpenUpdateTotalPrice = () => {
    setOpenTotalPrice(true);
  };

  const updateTotalPrice = async () => {
    try {
      setLoadingTotalPrice(true);

      const { data } = await axios.post(INVOICE_ROUNDING_BASE_URL, {
        id: invoice.id,
        totalPrice
      });

      setInvoice(data.data);
      setTotalPrice(data.data.totalPrice);
      handleOpenSnackBar('success', 'Total biaya telah berhasil diperbaharui.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Total biaya telah gagal diperbaharui.');
    } finally {
      setLoadingTotalPrice(false);
      setOpenTotalPrice(false);
    }
  };

  const handleCancelUpdateTotalPrice = () => {
    setOpenTotalPrice(false);
    setTotalPrice(invoice.totalPrice);
  };

  const fetchData = async () => {
    setIsLoadingData(true);

    try {
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      setId(data.data.id);
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const fetchCompany = async () => {
    try {
      const { data } = await axios.get(COMPANY_BASE_URL);
      setCompany(data.data);
    } catch (error) {
      console.log('error:', error);
    }
  };

  const fetchReturn = async (id: number) => {
    setLoadingCollapseReturn(true);
    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_RETURN_BASE_URL(id));
      const invoiceReturn = await axios.get(`${RETURN_INVOICE_BASE_URL}?id=${data.data.map((value: any) => value.ReturnId || 0).join(',')}`);
      setInvoiceReturn(invoiceReturn.data.data);
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoadingCollapseReturn(false);
    }
  };

  const fetchPayment = async () => {
    try {
      const { data } = await axios.get(GET_INVOICE_PAYMENT_LIST_BASE_URL(id));
      setInvoicePayment(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
    }
  };

  const addPayment = async () => {
    if (!paymentValidation()) {
      return;
    }

    try {
      setLoadingPayment(true);
      setSubmit(true);
      const { data } = await axios.post(INVOICE_PAYMENT_BASE_URL, {
        id: 0,
        InvoiceId: invoice.id,
        payDate: format(new Date(payDate), 'yyyy-MM-dd'),
        totalPay: totalPay || 0,
        paymentMethod,
        note,
        BankId: bank.id > 0 ? bank.id : null,
        giroBank,
        giroDueDate,
        giroAmount,
        giroNumber,
        status: paymentMethod === PaymentMethod.GIRO ? PaymentStatus.UNPAID : PaymentStatus.PAID
      });
      setInvoicePayment([data.data, ...invoicePayment]);
      setInvoice(prevState => {
        prevState.totalPay = prevState.totalPay + data.data.totalPay;
        return prevState;
      });

      handleOpenSnackBar('success', 'Pembayaran telah berhasil');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Pembayaran telah gagal');
    } finally {
      reset();
      resetValidation();
      setLoadingPayment(false);
      setSubmit(false);
      setOpenCreate(false);
    }
  };

  const getPartner = async () => {
    setIsPartner(true);
    try {
      const { data } = await axios.get(PARTNER_DETAIL_BASE_URL(invoice.PartnerId), { cancelToken: cancelTokenSource.token });
      setPartner(data.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsPartner(false);
    }
  };

  const onCancel = async () => {
    setCancelLoading(true);
    try {
      if (isAdmin) {
        const { data } = await axios.post(INVOICE_REQUEST_CANCEL_BASE_URL, {
          id: invoice.id,
          cancelNote
        });

        setInvoice(prevState => {
          prevState.cancelNote = cancelNote;
          prevState.isRequestEdit = true;
          return prevState;
        });

        handleOpenSnackBar('success', 'Invoice berhasil request batal');
      } else {
        const { data } = await axios.post(INVOICE_CANCEL_BASE_URL, {
          id: invoice.id,
          cancelNote
        });

        setInvoice(prevState => {
          prevState.cancelAt = data.data.cancelAt;
          prevState.statusPayment = data.data.statusPayment;
          prevState.cancelNote = data.data.cancelNote;
          prevState.statusPayment = 'CANCEL';
          return prevState;
        });

        handleOpenSnackBar('success', 'Invoice berhasil dibatalkan');
      }
    } catch (error) {
      console.log('error:', error);
      handleOpenSnackBar('error', 'Invoice gagal dibatalkan');
    } finally {
      setRequestCancel(true);
      setCancelLoading(false);
      setCancel(false);
    }
  };

  const printPdf = async () => {
    setLoadingPrint(true);

    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/pdf/${id}`, { responseType: 'blob' });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      setLoadingPrint(false);
      setMessagePrintDate(format(new Date(), 'dd/MM/yyyy HH:mm:ss'));
      setMessagePrintUser(currentUser ? currentUser.firstName : '');
      handleClosePrintConfirm();
      setInvoice({ ...invoice, printDate: new Date(), canPrint: false, requestPrint: false });

      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus(); // Call the function directly
      }
    } catch (error) {
      console.log('error :', error);
      setLoadingPrint(false);
    }
  };

  const addPiece = async () => {
    try {
      setLoadingPiece(true);
      setSubmit(true);
      const { data } = await axios.post(INVOICE_PIECE_BASE_URL, {
        id: 0,
        InvoiceReturnId: null,
        InvoiceId: invoice.id,
        price: piecePrice,
        note: pieceNote,
        type: pieceType
      });
      setInvoicePiece([data.data, ...invoicePiece]);
      setInvoice(prevState => {
        prevState.totalPiece = prevState.totalPiece + data.data.price;
        return prevState;
      });

      setTotalPrice(totalPrice - data.data.price);
      handleOpenSnackBar('success', 'Potongan telah berhasil');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Potongan telah gagal');
    } finally {
      reset();
      setLoadingPiece(false);
      setSubmit(false);
      setOpenCreatePiece(false);
    }
  };

  const deletePiece = (pieceId: number): React.MouseEventHandler => async () => {
    setLoadingPiece(true);

    try {
      await axios.delete(`${INVOICE_PIECE_BASE_URL}/${pieceId}`);
    } catch (error) {
      handleOpenSnackBar('error', 'Hapus Potongan telah gagal');
    } finally {
      const piece = invoicePiece.find(value => value.id === pieceId);

      setInvoice(prevState => {
        prevState.totalPrice = prevState.totalPrice + (piece ? piece.price : 0);
        prevState.totalPiece = prevState.totalPiece - (piece ? piece.price : 0);
        return prevState;
      });

      setTotalPrice(totalPrice + (piece ? piece.price : 0));
      setInvoicePiece(prevState => prevState.filter(value => value.id !== pieceId));

      setLoadingPiece(false);
      setSubmit(false);
      setOpenCreatePiece(false);
      handleOpenSnackBar('success', 'Hapus Potongan telah berhasil');
    }
  };

  const searchBank = async (value: string) => {
    const params = new URLSearchParams();
    params.append('keyword', value);
    try {
      setLoadingBank(true);
      const { data } = await axios.get(`${BANK_BASE_URL}?${params.toString()}`);
      setBanks(data.data);
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Potongan telah gagal');
    } finally {
      setLoadingBank(false);
    }
  };

  const onRefresh = () => {
    fetchData();
    setItemId([]);
    setIndexCollapse(-1);
    setRequestEdit(false);
  };

  const onSubmit = async () => {
    setLoadingEdit(true);
    try {
      await axios.post(INVOICE_ITEM_UPDATE_BASE_URL, currentItem);
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      setId(data.data.id);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const onRequest = async () => {
    setLoadingRequest(true);
    try {
      await axios.post(INVOICE_REQUEST_EDIT_BASE_URL, {
        id,
        items: itemId
      });
      handleOpenSnackBar('success', 'Permintaan telah dikirim.');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Permintaan gagal dikirim.');
    } finally {
      setLoadingRequest(false);
      setItemId([]);
      setRequestEdit(false);
      setIndexCollapse(-1);
    }
  };

  const onDeleteItem = async (id: number) => {
    setTypeEdit('DELETE');
    setLoadingEdit(true);
    try {
      await axios.delete(INVOICE_ITEM_DELETE_URL(id));
      setInvoiceItem(prevState => prevState.filter(value => value.id !== id));
      const { data } = await axios.get(GET_INVOICE_DETAIL_BASE_URL(parseInt(params)));
      const invoice = { ...data.data, totalPrice: data.data.totalPriceReal };

      setInvoice(invoice);
      setTotalPrice(invoice.totalPrice);
      setInvoiceItem(data.data.InvoiceItem);
      setInvoicePiece(data.data.InvoicePiece);
      handleOpenSnackBar('success', 'Item berhasil dihapus.');
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoadingEdit(false);
      setCurrentItem(undefined);
      setIndexEdit(-1);
      setTypeEdit('');
    }
  };

  const updateStatusNewEdit = async () => {
    try {
      await axios.get(INVOICE_NEW_EDIT_BASE_URL(id));
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const handleOnSubmitRemark = async () => {
    try {
      const { data } = await axios.post(`${PARTNER_BASE_URL}/remark-address`, {
        id: partner.id,
        remarkAddress: remark
      });
      const current = { ...invoice!.Partner, remarkAddress: data.data.remarkAddress };
      setInvoice({ ...invoice, Partner: current as any });
      setRemark('');
      setOpenRemark();
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const handleRequestPrint = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/request-print/${invoice.id}`);
      setInvoice({ ...invoice, requestPrint: data.data.requestPrint });
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const updateRequestPrint = async () => {
    try {
      const { data } = await axios.get(`${INVOICE_BASE_URL}/update-print/${invoice.id}`);
      setInvoice({ ...invoice, canPrint: data.data.canPrint, requestPrint: data.data.requestPrint });
    } catch (error) {
      console.log('error', error);
    } finally {
    }
  };

  const paymentValidation = () => {
    let valid = true;
    if (paymentMethod === '' || !paymentMethod) {
      setPaymentMethodMessage('Metode Pembayaran tidak boleh kosong.');
      valid = false;
    }

    if (totalPay === 0 && !totalPay) {
      setTotalPayMessage('Jumlah Pembayaran tidak boleh kosong');
      valid = false;
    } else if (totalPay && totalPay > invoice.totalPrice - invoice.totalPay) {
      setTotalPayMessage('Melebihi batas pembayaran');
      valid = false;
    }

    if (!payDate) {
      setPayDateMessage('Tanggal Pembayaran tidak boleh kosong.');
      valid = false;
    }

    if (paymentMethod === PaymentMethod.GIRO) {
      if (!giroBank) {
        setGiroBankMessage('Bank Penerbit Giro harus diisi.');
        valid = false;
      }

      if (!giroNumber) {
        setGiroNumberMessage('No Giro harus diisi.');
        valid = false;
      }

      if (!giroAmount) {
        setGiroAmountMessage('Nominal Giro harus diisi.');
        valid = false;
      }

      if (!giroDueDate) {
        setGiroDateMessage('Tanggal Giro harus diisi.');
        valid = false;
      }
    }

    return valid;
  };

  const reset = () => {
    setPaymentMethod('');
    setTotalPay(null);
    setPayDate(new Date());
    setNote('');
    setPiecePrice(0);
    setPieceNote('');
    setPieceType('OTHER');
  };

  const resetValidation = () => {
    setPaymentMethodMessage('');
    setTotalPayMessage('');
    setPayDateMessage('');
  };

  const handleOpenCreatePiece = () => {
    setOpenCreatePiece(true);
  };

  const handleCloseCreatePiece = () => {
    setOpenCreatePiece(false);
  };

  const handleOpenPrintConfirm = () => {
    if (invoice.printDate && invoice.userPrint) {
      setMessagePrintDate(format(new Date(invoice.printDate), 'dd/MM/yyyy HH:mm:ss'));
      setMessagePrintUser(invoice.userPrint);
    }

    setOpenPrintConfirm(true);
  };

  const handleClosePrintConfirm = () => {
    setOpenPrintConfirm(false);
  };

  const handleOpenSnackBar = (variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleEdit = (id: number, type: string) => {
    setIndexEdit(prevState => (prevState === id ? -1 : id));
    setTypeEdit(prevState => (prevState === type ? '' : type));
  };

  const handleRequestEdit = () => {
    setRequestEdit(prevState => !prevState);

    setItemId(prevState => {
      if (invoice.isRequestEdit) {
        return invoiceItem
          .filter(value => value.isRequestEdit)
          .map(value => {
            return { id: value.id, notes: value.notes || '', isRequestEdit: value.isRequestEdit || false };
          });
      } else {
        return [];
      }
    });
  };

  const handleOpenCollapse = (index: number): React.MouseEventHandler => () => {
    setIndexCollapse(indexCollapse === index ? -1 : index);
  };

  const handleRequestCheck = (item: { id: number; notes: string; isRequestEdit: boolean }) => {
    setItemId(prevState => {
      if (prevState.some(value => value.id === item.id)) {
        setIndexCollapse(-1);
        const _item = invoiceItem.find(_value => _value.id === item.id);

        if (_item && _item.isRequestEdit) {
          return prevState.map(value => {
            if (value.id === item.id) {
              value.isRequestEdit = !value.isRequestEdit;
              value.notes = '';
            }
            return value;
          });
        } else {
          return prevState.filter(value => value.id !== item.id);
        }
      } else {
        setIndexCollapse(item.id);
        return [...prevState, item];
      }
    });
  };

  const individualEdit = (item: InvoiceItemModel) => {
    let currItem = item;

    currItem.totalPrice = currItem.price * currItem.totalItem - ((currItem.discountUnit && currItem.discountUnit) || 0) * currItem.totalItem;
    //currItem.discount = ((currItem.discountUnit && currItem.discountUnit) || 0) * currItem.totalItem;
    currItem.subTotalPrice = currItem.price * currItem.totalItem;

    setInvoiceItem(prevState =>
      prevState.map(value => {
        if (value.id === currItem.id) {
          value = currItem;
        }

        return value;
      })
    );

    setCurrentItem(currItem);
  };

  const handleRequestNote = (item: { id: number; notes: string }) => {
    const index = itemId.findIndex(value => value.id === item.id);
    if (index >= 0) {
      itemId[index].notes = item.notes;
      setItemId([...itemId]);
    }
  };

  const handleChange = ({}, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    if (id === 0) return;
    fetchPayment();
  }, [id]);

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    if (invoiceItem.length === 0) return;

    let totalDiscount = invoiceItem
      .filter(value => !value.isDeleted)
      .map(value => value.discount)
      .reduce((a, b) => a + b);

    let total = invoiceItem
      .filter(value => !value.isDeleted)
      .map(value => value.totalPrice)
      .reduce((a, b) => a + b);

    setInvoice(prevState => {
      prevState.totalDiscount = totalDiscount;
      prevState.totalPrice = total;
      return prevState;
    });
  }, [invoiceItem]);

  useEffect(() => {
    if (invoice.id === 0) return;

    if (invoice.isRequestEdit && invoice.cancelNote) {
      setRequestCancel(true);
      setCancelNote(invoice.cancelNote);
    }

    if (!invoice.isNewEdited) return;

    updateStatusNewEdit();
  }, [invoice, id]);

  useEffect(() => {
    if (!openPartner && !openRemark) {
      return;
    }

    getPartner();
  }, [openPartner, openRemark]);

  const hasPrint = !!invoice.printDate;
  const hasRemarkAddress = invoice.Partner && invoice.Partner.remarkAddress && invoice.Partner.remarkAddress !== '' ? true : false;
  const canPrint = hasPrint ? (invoice.canPrint ? hasRemarkAddress : false) : hasRemarkAddress;

  return (
    <Page title='Invoice Penjualan'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
            <Typography variant='h1'> Penjualan </Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} xl={6}>
            <Breadcrumb />
          </Grid>

          {!isAccounting && !isHR && (
            <Grid container item lg={6} sm={12} md={6} xs={12} xl={6} justify='flex-end' alignItems='center' style={{ gap: '1rem' }}>
              {!(invoice.statusPayment === 'CANCEL') && (
                <Badge
                  color='error'
                  variant='dot'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  invisible={!invoice.requestPrint}
                >
                  <ButtonGroup size='small'>
                    {(isSuperAdmin || isAdmin) && invoice.requestPrint && (
                      <Button
                        disabled={isLoadingData}
                        style={{ backgroundColor: WHITE, color: '#f57c00', cursor: 'pointer' }}
                        onClick={updateRequestPrint}
                      >
                        Izinkan Cetak
                      </Button>
                    )}

                    {!isSuperAdmin && !isAdmin && !invoice.canPrint && hasPrint ? (
                      <Button
                        disabled={isLoadingData}
                        style={{ backgroundColor: WHITE, color: BLUE_PRIMARY, cursor: 'pointer' }}
                        onClick={handleRequestPrint}
                      >
                        <PrintDisabledIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                        Meminta Cetak Invoice
                      </Button>
                    ) : (
                      <Button
                        disabled={isLoadingData || (!isSuperAdmin && !isFinance && !isAdmin && !isInvoice && !canPrint && !isAccounting)}
                        style={{ backgroundColor: WHITE, color: BLUE_PRIMARY, cursor: 'pointer' }}
                        onClick={handleOpenPrintConfirm}
                      >
                        {isLoadingData ? (
                          <CircularProgress size={20} color='inherit' />
                        ) : (
                          <>
                            {!isSuperAdmin && !isAdmin && !isFinance && !isInvoice && !canPrint && !isAccounting ? (
                              <PrintDisabledIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                            ) : (
                              <PrintIcon fontSize='small' style={{ verticalAlign: 'middle' }} />
                            )}
                            {` Cetak`}
                          </>
                        )}
                      </Button>
                    )}

                    {isAdmin && !isRequestCancel && (
                      <Button
                        disabled={isLoadingData || isRequestCancel || invoice.totalPay > 0}
                        style={{ backgroundColor: WHITE, color: RED }}
                        onClick={() => setCancel(prevState => !prevState)}
                      >
                        {isCancel ? 'Cancel Request' : 'Request Batal'}
                      </Button>
                    )}
                  </ButtonGroup>
                </Badge>
              )}
            </Grid>
          )}

          {(isCancel || isRequestCancel) && !(invoice.statusPayment === 'CANCEL') && (
            <PaperCustom>
              <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
                <code>{isAdmin ? 'Dengan ini saya sadar ingin mengajukan pembatalan invoice ini' : 'Catatan Pembatalan'}</code>
              </Grid>

              <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  label='Catatan Pembatalan'
                  value={cancelNote}
                  onChange={e => setCancelNote(e.target.value)}
                  multiline
                  rows={3}
                  placeholder='Tulis Catatan Disini'
                  disabled={isRequestCancel}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
                <Button
                  disabled={cancelLoading || (isAdmin && isRequestCancel)}
                  className={isSuperAdmin ? classes.cancelConfirmButton : 'containedPrimary'}
                  onClick={onCancel}
                >
                  {cancelLoading ? <CircularProgress size={20} color='primary' /> : isAdmin ? 'Request Batal' : 'Konfirmasi Batalkan'}
                </Button>
              </Grid>
            </PaperCustom>
          )}

          {invoice.statusPayment === 'CANCEL' ? (
            <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
              <Alert variant='filled' severity='error'>
                Invoice Ini telah dibatalkan pada tanggal {invoice.cancelAt || ''}
                <br />
                Catatan Pembatalan:
                <br />
                {invoice.cancelNote || '-'}
              </Alert>
            </Grid>
          ) : invoice.isEdited ? (
            <Grid item lg={12} md={12} sm={12} xs={12} xl={6}>
              <Alert variant='filled' severity='warning'>
                Invoice Ini telah di perbaharui pada tanggal {invoice.updatedAt || ''}
              </Alert>
            </Grid>
          ) : (
            ''
          )}

          <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1} className={classes.box}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Nama Mitra &nbsp;
                {invoice.typeInvoice && invoice.typeInvoice === 'ALLOWED' && (
                  <span style={{ verticalAlign: 'middle' }}>
                    <Tooltip title='Invoice ini melalui izin plafon.'>
                      <ErrorIcon fontSize='small' color='action' />
                    </Tooltip>
                  </span>
                )}
              </Typography>
              <Typography variant='body2'> {invoice.Partner ? invoice.Partner.name : ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Tanggal Terbit
              </Typography>
              <Typography variant='body2'> {invoice.createdAt ? format(new Date(invoice.createdAt), 'dd MMM yyyy') : ''} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' className={classes.boxTitle}>
                Jatuh Tempo
              </Typography>
              <Typography variant='body2'> {format(new Date(invoice.dueDate), 'dd MMM YYY')} </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Total Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
            <Hidden smUp smDown>
              <Divider orientation='vertical' />
            </Hidden>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
              <Typography variant='h6' align='right' className={classes.boxTitle}>
                Sisa Tagihan
              </Typography>
              <Typography align='right' variant='body2'>
                <NumberFormat value={totalPrice - invoice.totalPay} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container direction='row'>
          <Grid container item lg={12} xl={12} md={12} sm={12} xs={12}>
            <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange} className={classes.panel}>
              <Tab label='Invoice Detail' {...a11yProps(0)} />
              {!isAccounting && !isHR && <Tab label='Potongan' {...a11yProps(1)} />}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} xl={12} md={12} sm={12} xs={12} container>
            <TabPanel value={value} index={0} className={classes.panel}>
              <PaperCustom>
                <ContentInvoice
                  isCancel={invoice.statusPayment === 'CANCEL'}
                  onDeleteItem={onDeleteItem}
                  onRefresh={onRefresh}
                  isAdmin={isAdmin}
                  isSuperAdmin={isSuperAdmin}
                  loadingRequest={loadingRequest}
                  isFinance={isFinance}
                  loadingEdit={loadingEdit}
                  isInvoice={isInvoice}
                  onUpdate={onSubmit}
                  individualEdit={individualEdit}
                  invoice={invoice}
                  invoiceItem={invoiceItem}
                  invoicePayment={invoicePayment}
                  company={company}
                  isLoadingData={isLoadingData}
                  totalPrice={totalPrice}
                  openTotalPrice={openTotalPrice}
                  loadingTotalPrice={loadingTotalPrice}
                  isRequestEdit={isRequestEdit}
                  item={itemId}
                  indexCollapse={indexCollapse}
                  indexEdit={indexEdit}
                  type={typeEdit}
                  handleOpenRemark={setOpenRemark}
                  setOpenPartner={setOpenPartner}
                  handleRequest={onRequest}
                  handleEdit={handleEdit}
                  handleRequestNote={handleRequestNote}
                  handleRequestCheck={handleRequestCheck}
                  handleRequestEdit={handleRequestEdit}
                  handleOpenCollapse={handleOpenCollapse}
                  setTotalPrice={setTotalPrice}
                  handleOpenUpdateTotalPrice={handleOpenUpdateTotalPrice}
                  handleCancelUpdateTotalPrice={handleCancelUpdateTotalPrice}
                  updateTotalPrice={updateTotalPrice}
                />
              </PaperCustom>
            </TabPanel>

            <TabPanel value={value} index={1} className={classes.panel}>
              <PieceTable
                totalPrice={totalPrice}
                isLoadingData={isLoadingData}
                invoicePiece={invoicePiece}
                invoice={invoice}
                isSubmit={isSubmit}
                price={piecePrice}
                priceMessage={piecePriceMessage}
                note={pieceNote}
                type={pieceType}
                openCreate={openCreatePiece}
                isLoadingPiece={loadingPiece}
                setPricePiece={setPiecePrice}
                setTypePiece={setPieceType}
                setNote={setPieceNote}
                handleSave={addPiece}
                handleClose={handleCloseCreatePiece}
                handleOpenCreatePiece={handleOpenCreatePiece}
                handleDelete={deletePiece}
              />
            </TabPanel>
          </Grid>
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
          message={message}
          open={openSnackbar}
          handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
          onConfirm={handleConfirmSnackbar}
          noCancelButton={true}
        />

        <PrintConfirm
          open={openPrintConfirm}
          isLoading={loadingPrint}
          messageDate={messagePrintDate}
          messageUser={messagePrintUser}
          handleClose={handleClosePrintConfirm}
          onConfirm={printPdf}
        />

        <PartnerModal partner={partner} open={openPartner} isLoadingData={isPartner} setOpen={setOpenPartner} />

        <RemarkDialog
          open={openRemark}
          remark={remark}
          isLoading={false}
          setRemark={setRemark}
          handleClose={setOpenRemark}
          handleOnSubmit={handleOnSubmitRemark}
        />
      </Container>
    </Page>
  );
};

export default InvoiceDetailPage;
