import React, { FC, useState, useEffect, useContext } from 'react';
import { Button, Container, Grid, makeStyles, Typography, Divider, CircularProgress, Tabs, Tab } from '@material-ui/core';
import axios from 'axios';
import useRouter from 'hooks/useRouter';
import DetailInvoice from './components/DetailInvoice';
import { Page, Breadcrumb, StandardConfirmationDialog } from 'components';
import { WHITE, RED } from 'constants/colors';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import { TabPanel, a11yProps } from 'components';
import {
  GET_PURCHASE_INVOICE_DETAIL_BASE_URL,
  COMPANY_BASE_URL,
  PURCHASE_INVOICE_BASE_URL,
  GET_PURCHASE_INVOICE_ITEMS_BASE_URL,
  PURCHASE_INVOICE_PIECE_BASE_URL
} from 'constants/url';
import { dummyPurchaseInvoice, dummyCompany } from 'utils/dummy';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import useRole from 'hooks/useRole';
import TypeUser from 'typings/enum/TypeUser';
import PieceTable from './components/PieceTable';

const useStyles = makeStyles({
  box: {
    backgroundColor: WHITE,
    borderRadius: '5px',
    padding: 24,
    border: '1px solid #F2F2F2',
    boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.08)',
    marginTop: 10,
    marginBottom: 10,
    overflowX: 'auto'
  },
  panel: {
    width: '100%'
  },
  cancelConfirmButton: {
    backgroundColor: RED
  },
  boxTitle: {
    marginBottom: 8
  }
});

const PurchaseInvoiceDetailPage: FC = () => {
  const { location } = useRouter();
  // eslint-disable-next-line
  const params: any = location.state;
  const classes = useStyles();

  const [purchaseInvoice, setPurchaseInvoice] = useState<PurchaseInvoiceModel>(dummyPurchaseInvoice);
  const [company, setCompany] = useState<CompanyModel>(dummyCompany);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const [piecePrice, setPiecePrice] = useState<number>(0);
  const [invoiceItem, setInvoiceItem] = useState<PurchaseInvoiceItemModel[]>([]);
  const [invoicePayment, setInvoicePayment] = useState<PurchaseInvoicePaymentModel[]>([]);
  const { currentUser } = useContext(CurrentUserContext);
  const [value, setValue] = useState(0);
  const [loadingPiece, setLoadingPiece] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [purchaseInvoicePiece, setPurchaseInvoicePiece] = useState<PurchaseInvoicePieceModel[]>([]);
  const [pieceNote, setPieceNote] = useState<string>('');
  const [pieceType, setPieceType] = useState<string>('OTHER');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [openCreatePiece, setOpenCreatePiece] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [piecePriceMessage, setPiecePriceMessage] = useState<string>('');

  const isAccounting = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.ACCOUNTING]
  });
  const fetchData = async () => {
    setIsLoadingData(true);

    Promise.all([
      axios.get(GET_PURCHASE_INVOICE_DETAIL_BASE_URL(params.id)),
      axios.get(GET_PURCHASE_INVOICE_ITEMS_BASE_URL(params.id)),
      axios.get(COMPANY_BASE_URL)
    ])
      .then(result => {
        const inv = result[0].data.data;
        setPurchaseInvoice(inv);
        setPurchaseInvoicePiece(inv.purchaseInvoicePiece);
        setId(inv.id);

        const itm = result[1].data.data;
        setInvoiceItem(itm);

        const cpy = result[2].data.data;
        setCompany(cpy);
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoadingData(false));
  };

  const isHR = useRole({
    type: (currentUser && currentUser.type) || TypeUser.SALES,
    allowed: [TypeUser.HR]
  });

  const downloadPdf = async () => {
    setIsLoadingDownload(true);
    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}/download/${id}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `purchase-invoice-${purchaseInvoice.invoiceNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      setIsLoadingDownload(false);
    } catch (error) {
      console.log('error :', error);
    }
  };

  const handleChange = ({}, newValue: number) => {
    setValue(newValue);
  };

  const fetchPayment = async () => {
    try {
      const { data } = await axios.get(`${PURCHASE_INVOICE_BASE_URL}/payment/${id}`);
      setInvoicePayment(data.data);
    } catch (error) {
      console.log('error:', error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, [params, value]);

  useEffect(() => {
    if (id === 0) return;
    fetchPayment();
  }, [id]);

  const handleOpenSnackBar = (variant: 'success' | 'error', message: string): void => {
    setSnackbarVariant(variant);
    setMessage(message);
    setOpenSnackbar(true);
  };

  const addPiece = async () => {
    try {
      setLoadingPiece(true);
      setSubmit(true);
      const { data } = await axios.post(PURCHASE_INVOICE_PIECE_BASE_URL, {
        id: 0,
        purchaseInvoiceId: purchaseInvoice.id,
        price: piecePrice,
        note: pieceNote,
        type: pieceType
      });
      setPurchaseInvoicePiece([data.data, ...purchaseInvoicePiece]);
      setPurchaseInvoice(prevState => {
        prevState.totalPiece = prevState.totalPiece + data.data.price;
        return prevState;
      });

      setTotalPrice(totalPrice - data.data.price);
      handleOpenSnackBar('success', 'Potongan telah berhasil');
    } catch (error) {
      console.log('error', error);
      handleOpenSnackBar('error', 'Potongan telah gagal');
    } finally {
      reset();
      setLoadingPiece(false);
      setSubmit(false);
      setOpenCreatePiece(false);
    }
  };

  const deletePiece = (pieceId: number): React.MouseEventHandler => async () => {
    setLoadingPiece(true);

    try {
      await axios.delete(`${PURCHASE_INVOICE_PIECE_BASE_URL}/${pieceId}`);
    } catch (error) {
      handleOpenSnackBar('error', 'Hapus Potongan telah gagal');
    } finally {
      const piece = purchaseInvoicePiece.find(value => value.id === pieceId);

      setPurchaseInvoice(prevState => {
        prevState.totalPrice = prevState.totalPrice + (piece ? piece.price : 0);
        prevState.totalPiece = prevState.totalPiece - (piece ? piece.price : 0);
        return prevState;
      });

      setTotalPrice(totalPrice + (piece ? piece.price : 0));
      setPurchaseInvoicePiece(prevState => prevState.filter(value => value.id !== pieceId));

      setLoadingPiece(false);
      setSubmit(false);
      setOpenCreatePiece(false);
      handleOpenSnackBar('success', 'Hapus Potongan telah berhasil');
    }
  };

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleOpenCreatePiece = () => {
    setOpenCreatePiece(true);
  };

  const handleCloseCreatePiece = () => {
    setOpenCreatePiece(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const reset = () => {
    setPiecePrice(0);
    setPieceNote('');
    setPieceType('OTHER');
  };

  return (
    <Page title='Purchase Invoice Detail'>
      <Container>
        <Grid container direction='row' spacing={1}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Typography variant='h1'> Pembelian </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Breadcrumb />
          </Grid>
          <Grid container item lg={6} sm={6} md={6} xs={6} justify='flex-end'>
            {!isAccounting && (
              <Button onClick={downloadPdf} disabled={isLoadingData || isLoadingDownload}>
                {isLoadingDownload || isLoadingData ? (
                  <CircularProgress size={20} color='primary' />
                ) : purchaseInvoice.isPrint ? (
                  'Download Ulang PDF'
                ) : (
                  'Download PDF'
                )}
              </Button>
            )}
          </Grid>
          <Grid item justify='space-between' lg={12} xl={12} md={12} sm={12} xs={12} container spacing={1} className={classes.box}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Nama Supplier
              </Typography>
              <Typography variant='body2'> {purchaseInvoice.Partner ? purchaseInvoice.Partner.name : ''} </Typography>
            </Grid>
            <Divider orientation='vertical' />
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Tanggal Terbit
              </Typography>
              <Typography variant='body2'> {format(new Date(purchaseInvoice.orderDate), 'dd MMM YYY')} </Typography>
            </Grid>
            <Divider orientation='vertical' />
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Jatuh Tempo
              </Typography>
              <Typography variant='body2'> {format(new Date(purchaseInvoice.dueDate), 'dd MMM YYY')} </Typography>
            </Grid>

            <Divider orientation='vertical' />
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Total Tagihan
              </Typography>
              <Typography variant='body2'>
                <NumberFormat value={purchaseInvoice.totalPrice} prefix={'Rp'} thousandSeparator={true} displayType='text' />
              </Typography>
            </Grid>
            <Divider orientation='vertical' />
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography variant='h6' className={classes.boxTitle}>
                Sisa Tagihan
              </Typography>
              <Typography variant='body2'>
                <NumberFormat
                  value={purchaseInvoice.totalPrice - purchaseInvoice.totalPay}
                  prefix={'Rp'}
                  thousandSeparator={true}
                  displayType='text'
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction='row'>
            <Grid container item lg={12} xl={12} md={12} sm={12} xs={12}>
              <Tabs value={value} indicatorColor='primary' textColor='inherit' onChange={handleChange} className={classes.panel}>
                <Tab label='Invoice Pembelian Detail' {...a11yProps(0)} />
                {!isAccounting && !isHR && <Tab label='Potongan' {...a11yProps(1)} />}
              </Tabs>
            </Grid>
          </Grid>
          <Grid container direction='row' spacing={2} justify='space-between'>
            <TabPanel value={value} index={0} className={classes.panel}>
              <DetailInvoice
                purchaseInvoice={purchaseInvoice}
                invoiceItem={invoiceItem}
                company={company}
                isLoadingData={isLoadingData}
                invoicePayment={invoicePayment}
              />
            </TabPanel>

            <TabPanel value={value} index={1} className={classes.panel}>
              <PieceTable
                totalPrice={totalPrice}
                isLoadingData={isLoadingData}
                invoicePiece={purchaseInvoicePiece}
                purchaseInvoice={purchaseInvoice}
                isSubmit={isSubmit}
                price={piecePrice}
                priceMessage={piecePriceMessage}
                note={pieceNote}
                type={pieceType}
                openCreate={openCreatePiece}
                isLoadingPiece={loadingPiece}
                setPricePiece={setPiecePrice}
                setTypePiece={setPieceType}
                setNote={setPieceNote}
                handleSave={addPiece}
                handleClose={handleCloseCreatePiece}
                handleOpenCreatePiece={handleOpenCreatePiece}
                handleDelete={deletePiece}
              />
            </TabPanel>
          </Grid>

          <StandardConfirmationDialog
            variant={snackbarVariant}
            titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
            message={message}
            open={openSnackbar}
            handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
            onConfirm={handleConfirmSnackbar}
            noCancelButton={true}
          />
        </Grid>
      </Container>
    </Page>
  );
};

export default PurchaseInvoiceDetailPage;
