import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { Button, Container, Grid, IconButton, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core';
import { GREEN, WHITE } from 'constants/colors';
import axios, { CancelTokenSource } from 'axios';
import { Breadcrumb, Page, PaperCustom, StandardConfirmationDialog } from 'components';
import FlexBox from 'components/FlexBox';
import { BALANCE_SHEET_BASE_URL, OPERATIONAL_BASE_URL, PRODUCT_SEARCH, STOCK_MOVEMENT_BASE_URL } from 'constants/url';
import { format, startOfMonth } from 'date-fns';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchInput from 'components/SearchInput';
import { Today } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
const BalanceSheetContent = lazy(() => import('./components/CreateBalanceSheetContent'));

const useStyles = makeStyles((theme: Theme) => ({
  refresh: {
    backgroundColor: GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: GREEN
    }
  }
}));

export type TListExpand = {
  id: number | string;
  action: 'products' | 'months';
};

export type TFilters = {
  keyWord: string;
  productName: string;
  categoryId: number;
  isProductPackage: boolean;
};

export type TData<T> = {
  isLoading: boolean;
  data: T;
};

export const dummyFilters: TFilters = {
  productName: '',
  keyWord: '',
  isProductPackage: false,
  categoryId: 0
};

export type TypeOperasionalResource = {
  isLoading: boolean;
  data: Operasional[];
};

export type TypeBalanceSheet = {
  isLoading: boolean;
  data: BalanceSheet;
};

/* Creating a dummyData object with two properties: isLoading and data. */
const dummyData = {
  isLoading: true,
  data: []
};

const dummyBalanceSheet: BalanceSheet = {
  cash: 0,
  briBank: 0,
  mandiriBank: 0,
  bcaBank: 0,
  giro: 0,
  totalCash: 0,
  accountsReceivable: 0,
  purchaseInvoiceReturn: 0,
  InvoiceReturnBill: 0,
  purchaseAdvance: 0,
  endingInventory: 0,
  totalAccountsReceivable: 0,
  TotalCurrentAssets: 0,
  vehicles: 0,
  buildings: 0,
  equipmentAndMachinery: 0,
  totalAsetTetap: 0,
  totalActiva: 0,
  accountsPayableToSuppliers: 0,
  salesPayment: 0,
  totalShortLiabilities: 0,
  bankLoan: 0,
  totalLiabilities: 0,
  ownerCapital: 0,
  retainedEarnings: 0,
  salesDeposit: 0,
  purchaseDeposit: 0,
  currentPeriodNetProfit: 0,
  totalEkuitas: 0,
  totalPassiva: 0,
  bank: [],
  asset: [],
  totalModal: 0,
  modal: [],
  loan: [],
  totalLoan: 0,
  bankLoanResult: [],
  receivablesEmployees: 0,
  invoicePaymentGiroUnpaid: 0,
  purchaseInvoicePaymentGiroUnpaid: 0
};

const StockMovementPage = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<string>(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'));
  const [operasionalResource, setOperasionalResource] = useState<TypeOperasionalResource>({
    isLoading: false,
    data: []
  });
  const [openCalendarFilter, setOpenCalendarFilter] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [balanceSheet, setBalanceSheet] = useState<TData<BalanceSheet>>({
    isLoading: false,
    data: dummyBalanceSheet
  });

  const [omset, setOmset] = useState<boolean>(false);
  const [kas, setKas] = useState<boolean>(false);
  const [bankLoan, setBankLoan] = useState<boolean>(false);
  const [tradeReceivables, setTradeReceivables] = useState<boolean>(false); // piutang usaha
  const [purchase, setPurchase] = useState<boolean>(false);
  const [hpp, setHpp] = useState<boolean>(false);
  const [costOperational, setCostOperational] = useState<boolean>(false);

  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const handleCalendarFilterClick = () => setOpenCalendarFilter(true);

  const handleConfirmSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const fetchCost = useCallback(async () => {
    setOperasionalResource(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));
      return params;
    };

    try {
      const { data } = await axios.get(`${OPERATIONAL_BASE_URL}?${getQueryParams()}`, {
        cancelToken
      });
      setOperasionalResource(prev => ({ ...prev, isLoading: false, data: data.data }));
    } catch (error) {
      console.log(error);
    }
  }, [startDate, endDate]);

  const getForm = async () => {
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setBalanceSheet(prev => ({ ...prev, isLoading: true }));
    const getQueryParams = () => {
      const params = new URLSearchParams();

      params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
      params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

      return params;
    };

    try {
      const { data } = await axios.get(`${BALANCE_SHEET_BASE_URL}/form?${getQueryParams()}`, {
        cancelToken
      });
      console.log(data);
      setBalanceSheet(prev => ({ ...prev, isLoading: false, data: data }));
    } catch (error) {
      console.log(error);
    }
  };

  const onRefresh = () => {
    setStartDate(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    setEndDate(format(new Date(), 'yyyy-MM-dd'));
  };

  const handleDownload = async () => {
    const params = new URLSearchParams();

    params.append('startDate', format(new Date(startDate), 'yyyy-MM-dd'));
    params.append('endDate', format(new Date(endDate), 'yyyy-MM-dd'));

    try {
      const { data } = await axios(`${BALANCE_SHEET_BASE_URL}/download-pdf?${params}`, { responseType: 'blob' });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
      if (newwindow) {
        newwindow.focus();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCost();
    getForm();
  }, [startDate, endDate]);

  return (
    <Page title='Neraca'>
      <Container>
        <PaperCustom>
          <FlexBox container rowGap={1}>
            <StandardConfirmationDialog
              variant={snackbarVariant}
              titleMessage={snackbarVariant === 'success' ? 'Success!' : 'Error!'}
              message={message}
              open={openSnackbar}
              handleClose={snackbarVariant === 'success' ? handleConfirmSnackbar : handleCloseSnackbar}
              onConfirm={handleConfirmSnackbar}
              noCancelButton={true}
            />
            <Grid container direction='row' spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container direction='row' justify='space-between'>
                  <Grid item xl={7} lg={7} md={12} sm={12} xs={12} container alignItems='center'>
                    <Tooltip title='Memuat Ulang'>
                      <Button size='small' onClick={onRefresh} color='inherit' className={classes.refresh}>
                        <RefreshIcon fontSize='small' />
                      </Button>
                    </Tooltip>
                    <Typography style={{ fontSize: '1rem', fontWeight: 400, marginLeft: '15px' }}>
                      {`Laporan Neraca`}

                      <Typography color='primary'>
                        Data tanggal {format(new Date(startDate), 'dd-MM-yyyy')} s/d {format(new Date(endDate), 'dd-MM-yyyy')}
                      </Typography>
                    </Typography>
                  </Grid>

                  <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
                    <Grid item>
                      <Tooltip title='Calendar filter' placement='top'>
                        <Button color='primary' variant='contained' startIcon={<Today />} onClick={handleCalendarFilterClick}>
                          Filter
                        </Button>
                      </Tooltip>
                      <Tooltip title='Download Pdf' placement='top'>
                        <Button
                          color='primary'
                          variant='contained'
                          startIcon={<PictureAsPdfRoundedIcon />}
                          onClick={handleDownload}
                          style={{ marginLeft: '15px' }}
                        >
                          Download
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Suspense
              fallback={
                <Grid xs={12} container justify='center' alignItems='center'>
                  <Typography variant='h6'>Sedang Membuka...</Typography>
                </Grid>
              }
            >
              <BalanceSheetContent
                balanceSheet={balanceSheet}
                kas={kas}
                setKas={setKas}
                tradeReceivables={tradeReceivables}
                setTradeReceivables={setTradeReceivables}
                omset={omset}
                setOmset={setOmset}
                bankLoan={bankLoan}
                setBankLoan={setBankLoan}
                hpp={hpp}
                setHpp={setHpp}
                purchase={purchase}
                setPurchase={setPurchase}
                costOperational={costOperational}
                setCostOperational={setCostOperational}
                operasionalResource={operasionalResource}
              />
            </Suspense>
          </FlexBox>
          <DateRangeFilter
            openCalendarFilter={openCalendarFilter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleClose={() => setOpenCalendarFilter(false)}
          />
        </PaperCustom>
      </Container>
    </Page>
  );
};

export default StockMovementPage;
